
import { useCallback, useState } from 'react';
import { Spin, Space, Row, Col, Pagination, Card } from 'antd';
import dayjs from 'dayjs';

import { useGetNotifications } from '../../api/notifications/use-get-notification';
import { GET_NOTIFICATIONS, URL_UPDATE_NOTIFICATION } from '../../api/notifications/constants';
import { DEFAULT_PAGE } from '../../helpers/constants';
import { NotificationModal } from './notification-modal';
import { usePostNotification } from '../../api/notifications/use-post-notification';
import { errorMessage } from '../../helpers/utils';
import { EyeOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown } from '../../components/dropdown';
import { Button } from '../../components/button';

const items = [
    {
        key: true,
        label: 'Կարդացված',
    },
    {
        key: false,
        label: 'Չկարդացված',
    }
];


export const Notifications = () => {
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [notificationItem, setNotificationItem] = useState('');

    const [result, setResult] = useState({ data: [], totalRecords: 0 });
    const { isLoading, refetch } = useGetNotifications(GET_NOTIFICATIONS, page, {
        onSuccess: (data) =>
            setResult(() => ({
                data: data.data,
                totalRecords: data.totalRecords,
            })),
        enabled: true,
    });

    const { mutate: updateNotificationStatus } = usePostNotification(URL_UPDATE_NOTIFICATION, {
        onError: errorMessage,
    });
    const handleModalCancel = useCallback(() => {
        setNotificationItem('');
        refetch();
    }, [refetch]);

    const onHandleChange = useCallback((current, pageSize) => {
        setPage((prev) => ({
            ...prev,
            pageNumber: current,
            pageSize: pageSize,
        }));
    }, []);

    const onClick = ({ key }) => {
        setPage(() => ({
            isRead: key
        }));
    };
    const handleShowClick = useCallback(
        (item) => {
            if (item) {
                updateNotificationStatus({ notificationId: item?.id, status: true });
            }
            setNotificationItem(item);
        },
        [updateNotificationStatus]
    );
    return (
        <Spin spinning={isLoading}>
            <Space direction="vertical" style={{ width: '95%' }} size="middle">
                <Row justify='end' >
                    <Col sm={4} md={2}>
                        <Dropdown
                            menu={{
                                items,
                                onClick
                            }}
                            placement="bottomLeft"
                        >
                            <Button type="primary" onClick={e => e.preventDefault()}>
                                <Space size="middle"> Ֆիլտրել<DownOutlined /></Space>
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>

                <Card style={{ minHeight: '100vh' }}>
                    {result?.data &&
                        result?.data.map((item) => {
                            return (
                                <Card title="" key={item?.id} style={{ margin: '10px' }} bodyStyle={{ borderLeft: '2px solid #FA396B', minHeight: '116px' }}>
                                    <Row align="middle" gutter={16}>
                                        <Col span={19}>
                                            <Row size="middle" gutter={[4, 4]}>
                                                <Col span={24}>
                                                    <div dangerouslySetInnerHTML={{ __html: item?.notificationText.length > 150 ? `${item?.notificationText.substring(0, 150)}...` : item?.notificationText }}></div>
                                                </Col>
                                                <Col span={24} >
                                                    {item?.dateProcessed && dayjs(item?.dateProcessed).format('DD/MM/YYYY HH:mm:ss')}
                                                </Col>
                                                <Col span={24}>
                                                    <b>{item?.isRead === true ? 'Կարդացված' : 'Չկարդացված'}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4} push={3}>
                                            <EyeOutlined onClick={() => handleShowClick(item)} style={item?.isRead === false ? { color: '#FA396B' } : {}} />
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })}
                </Card>
                {notificationItem && <NotificationModal item={notificationItem} handleModalCancel={handleModalCancel} />}
                <Space style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination
                        showSizeChanger={false}
                        onChange={onHandleChange}
                        defaultCurrent={page?.pageNumber}
                        total={result?.totalRecords}
                    />
                </Space>
            </Space>
        </Spin>
    )
}
