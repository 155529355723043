import { Col, Radio, Row } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

import { VerticalSpace } from '../../components/space/vertical-space';
import { ApplicationInfoForm } from '../../components/form/application/application-info-full-form';
import { SectionWrapper } from '../../components/collapse/section-wrapper';
import { LegalRepresentative } from '../../components/form/application/application-not-for-me/legal-representative';
import { FormSectionCard } from '../../components/card/form-section-card';
import { PersonDataForm } from '../../components/form/person-data-form';
import {
  formItemActualAddressStepTwoView,
  formItemAddressType,
  formItemDocStepTwo,
  formItemRegAddressStepTwoView,
  formItemEducation,
  formItemUtility,
  formItemMilitary,
  formItemPropStepTwoView,
} from '../application/constants';
import { PersonalInfo } from '../../components/form/application/personal-info';
import { RegistrationAddress } from '../../components/form/application/registration-address';
import { PrintButton } from '../../components/button/print-button';
import { ApplicationHistory } from '../../list/application-list';
import { FormItem } from '../../components/form/form-item';
import { TextArea } from '../../components/input';
import { ViewFamilyLivestock } from '../../components/form/application/family-has-livestock/view';
import { Form } from '../../components/form/form';
import { PATHS } from '../../helpers/constants';
import { UtilityAccountsForm } from '../../components/form/application/utility-accounts-form';
import { EducationFormView } from 'components/form/application/education-form/view';
import { MilitaryFormView } from 'components/form/application/military-form/view';
import { FamilyDataView } from './family-data-view';
import { UploadSubmitDocument } from './upload-submit-document';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { BankData } from 'components/form/application/bank';
import {Info} from "../../components/typography/info";
import { Button } from 'components/button';
import { EditOutlined } from "@ant-design/icons";

export const ApplicationViewFormData = () => {
  const params = useParams();
  const navigate = useNavigate();

  const form = Form.useFormInstance();

  const profileData = Form.useWatch('profileData', { preserve: true });
  const status = Form.useWatch('status', { preserve: true });
  
  return (
    <VerticalSpace>
      <ApplicationInfoForm />
      <Row justify="end" gutter={10}>
        <Col span={7}>
          <PrintButton
            block
            text="Տպել թերթիկը"
            onClick={() =>
              window.open(`${PATHS.FAMILY_AGREEMENT_PRINT.replace(':id', params.id)}`, '_blank', 'noopener,noreferrer')
            }
            disabled={false}
          />
          <Info text="Այս հրահանգով պետք է գեներացնել և տպել տվյալ դիմումի Համաձայնության թերթիկը, որը պետք է ստորագրվի ընտանիքի բոլոր չափահաս անդամների կողմից" />
        </Col>
          <Col span={7}>
              <UploadSubmitDocument />
              <Info text="Այս հրահանգով պետք է կցել ընտանիքի բոլոր չափահաս անդամների կողմից ստորագրված Համաձայնության թերթիկը" />
          </Col>
      </Row>
      <Row justify="end">
          <Col span={7}>
              <PrintButton
                  block
                  text="Տպել դիմումը"
                  onClick={() =>
                      window.open(`${PATHS.APPLICATIONS_PRINT.replace(':id', params.id)}`, '_blank', 'noopener,noreferrer')
                  }
                  disabled={false}
              />
              <Info text="Այս հրահանգով հնարավոր է տպել տվյալ դիմումի ձևաթուղթը" />
          </Col>
      </Row>
      {profileData && (
        <SectionWrapper title="Օրինական ներկայացուցչի տվյալներ">
          <LegalRepresentative viewMode />
          <FormSectionCard title="Հանդիսանում է">
            <VerticalSpace size="large">
              <Radio checked>{form.getFieldValue(['profileData', 'legalRepresentativeType'])}</Radio>
              <UploadApplicationDocument noInfo checksumName={['profileData', 'legalFilesChecksum']} />
            </VerticalSpace>
          </FormSectionCard>
        </SectionWrapper>
      )}
      <SectionWrapper title="Դիմումատուի տվյալներ">
        <VerticalSpace>
          <PersonDataForm
            title="Դիմումատուի տվյալներ"
            formItemProp={formItemPropStepTwoView}
            formItemContactProp={formItemPropStepTwoView.contact}
            viewMode
          />
          <PersonalInfo viewMode formItemDoc={formItemDocStepTwo} />
          <RegistrationAddress
            formItemRegAddress={formItemRegAddressStepTwoView}
            formItemActualAddressStepTwo={formItemActualAddressStepTwoView}
            formItemAddressType={formItemAddressType}
            viewMode
          />
          <UtilityAccountsForm formItemUtility={formItemUtility} />
          <EducationFormView formItemEducation={formItemEducation} />
          <MilitaryFormView formItemMilitary={formItemMilitary} />
          <ViewFamilyLivestock />
        </VerticalSpace>
      </SectionWrapper>
      <FamilyDataView />
      <SectionWrapper title="Վճարման եղանակ">
        <BankData />
      </SectionWrapper>
      <SectionWrapper title="Նշումներ">
        <VerticalSpace>
          {status === 'Հարցված' && <Row justify="end" gutter={10}>
            <Col>
              <Button size='large' disabled={false} onClick={() => navigate(`${PATHS.APPLICATIONS_UPDATE.replace(':id', params.id)}`)}>Խմբագրել դիմումը <EditOutlined /></Button>
            </Col>
          </Row>
          }
        </VerticalSpace>
        <VerticalSpace size="large">
          <FormSectionCard title="Հարցման նպատակը՝">
            <FormItem name="notes">
              <TextArea placeholder="Լրացնել" />
            </FormItem>
          </FormSectionCard>
          <ApplicationHistory />
        </VerticalSpace>
      </SectionWrapper>
    </VerticalSpace>
  );
};
