import {URL_LOGIN2F_NID_USER} from "../../api/auth/constants";
import {PATHS, REQUEST_TYPES} from "../../helpers/constants";
import {useLocation, useNavigate} from "react-router-dom";
import {Col, Row, Spin} from "antd";
import Coat from '../../icons/gerb.svg';
import {Title} from "../typography";
import {useMutateUserData} from "../../api/auth/use-mutate-user-data";
import {useAuth} from "../../context/auth-context";
import {useEffect} from "react";

const defaultStyle = {
    marginBottom: '20px',
    padding: '30px 50px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    alignItems: 'center',
    height: '97vh',
}

export const NidLoginCallback = () => {
    const location = useLocation();
    const {login} = useAuth();
    const navigate = useNavigate();

    const { mutate } = useMutateUserData(URL_LOGIN2F_NID_USER, REQUEST_TYPES.POST, {
        onSuccess: (payload) => {
            login(payload.data);
            setTimeout(() => navigate(parseInt(localStorage.getItem('dataParam')) === 0 ? `${PATHS.APPLICATION}` : `${PATHS.SERVICES}`), 2000);
        },
        onError: () => {
            navigate(`${PATHS.SIGN_IN}`);
        },
    });

    useEffect(() => {
        mutate({ssn: location?.state?.ssn, accessToken: location?.state?.accessToken})
    }, [location, mutate])

    return (
        <Row justify={'center'} style={defaultStyle}>
            <Spin spinning={true}>
                <Col>
                    <img src={Coat} style={{width: '85px'}}/>
                </Col>
                <Col>
                    <Title style={{fontSize: '50px'}}>
                        e-social.am
                    </Title>
                </Col>
            </Spin>
        </Row>
    )
}