import { Layout } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
// import { useApplication } from '../../context/applicaton-context';

// import { ReactComponent as StepIn } from '../../icons/step-in.svg';
// import { ReactComponent as StepNotVisited } from '../../icons/step-not-visited.svg';
import { COLORS } from '../../helpers/constants';
import { ApplicationSteps } from '../steps/application-steps';

const { Sider: SiderComponent } = Layout;

const Sider = styled(SiderComponent)`
  padding-top: 145px;
  && {
    background-color: ${COLORS.PRIMARY.BLUE_LIGHT};
    box-shadow: 4px 4px 10px rgba(71, 26, 127, 0.1);
  }

  .ant-steps {
    padding-left: 35px;
  }

  &.ant-layout-sider-collapsed {
    .ant-steps {
      padding-left: 20px;
    }
  }
`;

export const ApplicationSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider width={400} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <ApplicationSteps collapsed={collapsed} />
    </Sider>
  );
};
