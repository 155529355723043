import { Col, Row } from 'antd';
import { Datepicker } from '../datepicker';
import { Input } from '../input';
import { FormItem } from './form-item';
import { ArmenianInput } from 'components/input/armenian-input';

export const PersonMainData = ({ formItemProp = {} }) => {
  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <FormItem
            label="ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար"
            rules={[{ required: true }]}
            {...formItemProp.ssn}
          >
            <Input disabled={!!formItemProp.ssn?.readOnly} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <FormItem label="Անուն" rules={[{ required: true }]} {...formItemProp.fname}>
            <ArmenianInput disabled={!!formItemProp.fname?.readOnly} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Ազգանուն" rules={[{ required: true }]} {...formItemProp.lname}>
            <ArmenianInput disabled={!!formItemProp.lname?.readOnly} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <FormItem label="Հայրանուն" {...formItemProp.patronymicName}>
            <ArmenianInput disabled={!!formItemProp.patronymicName?.readOnly} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Ծննդյան ամսաթիվ" rules={[{ required: true }]} {...formItemProp.birthdate}>
            <Datepicker style={{ width: '100%' }} disabled={!!formItemProp.birthdate?.readOnly} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
