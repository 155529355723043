import { InputNumber as InputComponent } from 'antd';
import { changeHeight, placeholderSize } from '../../helpers/styles';
import styled, { css } from 'styled-components';

export const InputNumber = styled((props) => (
  <InputComponent
    onPaste={(e) => {
      e.preventDefault();
      return false;
    }}
    onCopy={(e) => {
      e.preventDefault();
      return false;
    }}
    {...props}
  />
))`
  ${(props) =>
    !props.size
      ? css`
          /* ${changeHeight} */
        `
      : ''}

  ::placeholder {
    ${placeholderSize}
  }
`;
