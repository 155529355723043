import {Col, Layout, Row} from "antd";
import {VerticalSpace} from "../../components/space/vertical-space";
import {Button} from "../../components/button";
import {useGetApplicationTypes} from "../../api/benefit/use-get-application-types";
import {URL_SYSTEM_TYPES} from "../../api/benefit/constants";
import {ContentWrapper} from "../../components/layouts/content-wrapper";
import {Text, Title} from "../../components/typography";
import {ServicesHeader} from "./services-header";
import {ServicesSider} from "../../components/layouts/service-sider";
import {COLORS, PATHS} from "../../helpers/constants";
import {LottieIcon} from "../../components/lottie";
import {ServicesDepartment} from "./services-department";
import {useAuth} from "../../context/auth-context";
import {Link} from "react-router-dom";
import {Card} from "../../components/card";
import {ManagePage} from "../../components/layouts/manage-page";

export const MyServices = () => {
  const { data } = useGetApplicationTypes(URL_SYSTEM_TYPES);
  const { user } = useAuth();

    return (
        <>
            {!user &&
                <>
                    <ManagePage/>
                    <ServicesHeader/>
                </>
            }
            <Layout>
                {!user &&
                    <ServicesSider/>}
                <Layout>
                    <ServicesDepartment/>
                    <ContentWrapper style={{paddingTop: 60, background: COLORS.SECONDARY.ALICE_BLUE}}>
                        <Row gutter={[60, 72]} align="center">
                            {data && data?.map((items, key) =>
                                items.id > 0 &&
                                <Col md={16} xl={12} xxl={8} key={key}>
                                    <Card bodyStyle={{minHeight: 425}} actions={[<Button size="large" key="button"
                                                            style={{width: 150, float: 'left', background:user ? COLORS.SECONDARY.AQUA_BLUE : COLORS.SECONDARY.OIL, margin: '0 20px'}}
                                                            type="primary">
                                        <Link style={{color: COLORS.PRIMARY.WHITE}}
                                              to={key===2 && !user ? PATHS.SIGN_IN : items.url}>Տեսնել ավելին</Link>
                                    </Button>]}>
                                        <LottieIcon src={items.icon}/>
                                        <VerticalSpace>
                                            <Title style={{fontSize:21}}>{items.displayName}</Title>
                                            <Text style={{fontSize: 17}}>{items.description}</Text>
                                        </VerticalSpace>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </ContentWrapper>
                </Layout>
            </Layout>
        </>
    )
}