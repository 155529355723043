import { Radio, Space } from 'antd';
import { COLORS } from '../../../../helpers/constants';
import { FormSectionCard } from '../../../card/form-section-card';
import { Text } from '../../../typography';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { Info } from 'components/typography/info';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

export const HasFamilyMember = () => {
  const form = Form.useFormInstance();
  const hasFamilyMember = Form.useWatch('familyData');
  const context = useOutletContext();
  useEffect(() => {
    if (hasFamilyMember && form.getFieldValue(['familyData', 'hasOtherFamilyMembers']) !== true && context?.isEdit === true) {
      form.setFieldValue(['familyData', 'hasOtherFamilyMembers'], false)
    }
  }, [form, hasFamilyMember, context])

  const onChange = (e) => {
    // Remove
    if (e.target.value === true) {
      form.getFieldValue(['familyData', 'familyMembers', 0, 'relativeType']);
    }

    return e.target.value;
  };
  return (
    <FormSectionCard title="Ընտանիքում կան այլ անդամներ">
      <FormItem rules={[{ required: true }]} name={['familyData', 'hasOtherFamilyMembers']} >
        <Radio.Group onChange={onChange} disabled={false}>
          <Space>
            <Radio value={true}>
              <Text color={COLORS.PRIMARY.BLUE_DARK}>այո</Text>
            </Radio>
            <Radio value={false}>
              <Text color={COLORS.PRIMARY.BLUE_DARK}>ոչ</Text>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      <Info text="Ընտանիքի անդամները պետք է հաշվառված լինեն նույն հասցեում" />
    </FormSectionCard>
  );
};
