export const URL_CREATE_APPLICATION = 'api/account/createBenefitApplication';
export const URL_CREATE_TEMP_APPLICATION = 'api/account/createIncompleteApplication';
export const URL_ADD_FAMILY_MEMBER = 'api/account/addFamilyMember';
export const URL_REMOVE_FAMILY_MEMBER = 'api/account/removeFamilyMember';
export const URL_VALIDATE_AND_GET_PERSON_DATA = 'api/account/validateSSNAndGetPersonData';
export const URL_CITIZEN_FOR_LEGAL = 'api/account/getCitizenForLegal';
export const URL_LEGAL_REPRESENTITIVE_INFO = 'api/account/getLegalRepresentativeInfo';
export const URL_CITIZEN_INFO = 'api/account/getCitizenInfo';
export const URL_ACCOUNT_INFO = 'api/account/getAccountInfo';

export const URL_GET_OWN_APPLICATIONS = 'api/Account/GetOwnApplications';
export const URL_GET_INVOLVED_APPLICATIONS = 'api/Account/GetInvolvedApplications';
export const URL_GET_LEGAL_APPLICATIONS = 'api/Account/GetLegalApplications';
export const URL_CLONE_APPLICATION = 'api/Account/CloneApplication';
export const URL_DELETE_INCOMPLETE_APPLICATION = 'api/Account/DeleteIncompleteApplication';
export const URL_EDIT_INCOMPLETE_APPLICATION = 'api/Account/EditIncompleteApplication';
export const URL_UPDATE_PHONE_NUMBER = 'api/Account/UpdatePhoneNumber';
export const URL_HAS_INCOMPLETE_APPLICATION = 'api/Account/HasIncompleteApplication';
export const URL_GET_APPLICATION = 'api/Account/ViewApplication';
export const URL_GET_INCOMPLETE_APPLICATION = 'api/Account/ViewIncompleteApplication';
export const URL_GET_CALENDAR = '/api/Account/GetCalendar';
export const URL_GET_FAMILY_AGREEMENT_PRINT = '/api/Account/GetFamilyAgreementApplicationPrintForm';
export const URL_DELETE_FILE = '/api/File/DeleteFile';
export const URL_PREVIEW_FILE = '/api/File/DownloadFileByName';
export const URL_GET_PRINT_APPLICATION = '/api/Account/PrintApplication';
export const URL_GET_DECISION_BY_ID = 'api/Account/GetDecisionById';
export const URL_NOTIFY_AGGREMENT_ATTACHED = '/api/Notification/NotifyBenefitAgreementAttached';
export const URL_GET_SERVICES = '/api/Account/GetServices';
export const URL_GET_SERVICE_BY_ID = '/api/Account/GetServiceById';
