import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';
import { SubHeader } from '../sub-header';
import { ReactComponent as Hand } from '../../../icons/hand.svg';

export const ApplicationLayout = ({ isEdit = false, hideHeader = false }) => {
  const context = useMemo(() => ({ isEdit }), [isEdit]);
  return (
    <Layout style={{ background: 'transparent' }}>
      {!hideHeader && <SubHeader icon={<Hand />} text="Դիմում ընտանիքի անապահովության նպաստ  ստանալու մասին" />}
      <Outlet context={context} />
    </Layout>
  );
};
