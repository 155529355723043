import { Col, Row } from 'antd';
import { FormSectionCard } from '../../card/form-section-card';
import { FormItem } from '../form-item';
import { Input } from '../../input';
import { Form } from '../form';

export const ApplicationInfoForm = () => {
  const number = Form.useWatch('number', { preserve: true });
  return (
    <FormSectionCard title={`Դիմում N ${number}`}>
      <Row gutter={10}>
        <Col span={12}>
          <FormItem label="Դիմումի տեսակը" rules={[{ required: true }]} name="systemType">
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Դիմումի ենթատեսակը" rules={[{ required: true }]} name="applicationType">
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <FormItem label="Դիմումի ստացման ամսաթիվը" rules={[{ required: true }]} name="createdDate">
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Կարգավիճակ" rules={[{ required: true }]} name="status">
            <Input />
          </FormItem>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
