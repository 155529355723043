import styled from 'styled-components';

const Fieldset = styled.fieldset`
  border: 2px solid #456fae;
  border-radius: 10px;
  padding: 29px 39px 19px;
`;

export const FormSectionFullCard = ({ children }) => {
  return <Fieldset>{children}</Fieldset>;
};
