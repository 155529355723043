import {URL_LOGIN2F_NID_USER, URL_NID_TOKEN_USER} from "../../api/auth/constants";
import {PATHS, REQUEST_TYPES} from "../../helpers/constants";
import {useNavigate} from "react-router-dom";
import {Col, Row, Spin} from "antd";
import Coat from './images/nationalid.svg';
import {Title} from "../typography";
import {errorMessage} from "../../helpers/utils";
import {useGetNidUrl} from "../../api/auth/use-get-nid-url";
import {useMutateUserData} from "../../api/auth/use-mutate-user-data";
import {useAuth} from "../../context/auth-context";
import {useState} from "react";

const defaultStyle = {
    marginBottom: '20px',
    padding: '30px 50px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    alignItems: 'center',
    height: '97vh',
}

export const OAuthCallback = () => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
    const state = queryParameters.get("state");
    const [data, setData] = useState();

    const {login} = useAuth();

    useGetNidUrl(URL_NID_TOKEN_USER, {code, state}, {
        onSuccess: (data) => {
            setData([data?.nidUserModel?.profile?.psn , data?.accessToken]);
            mutate({ssn: data?.nidUserModel?.profile?.psn, accessToken: data?.accessToken});
        },
        onError: (er) => {
            errorMessage(er.response.data.message);
            if (er.response.status === 400) {
                navigate(`${PATHS.SIGN_IN}`)
            }
        },
        enabled: true,
    });

    const {mutate} = useMutateUserData(URL_LOGIN2F_NID_USER, REQUEST_TYPES.POST, {
        onSuccess: (payload) => {
            login(payload.data);
            setTimeout(() => navigate(`${PATHS.SERVICES}`), 4000);
        },
        onError: (err) => {
            navigate(`${PATHS.SIGN_IN}`, { state: { headInfo: err?.response?.data?.avvResponse , loginOrReg: 2, loginData: data } });
        },
    });

    return (
        <Row justify={'center'} style={defaultStyle}>
            <Spin spinning={true}>
                <Col>
                    <img src={Coat} style={{width: '85px'}}/>
                </Col>
                <Col>
                    <Title style={{fontSize: '50px'}}>
                        National
                        <span style={{color: '#18BBB4'}}>ID</span>
                    </Title>
                </Col>
            </Spin>
        </Row>
    )
}