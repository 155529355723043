import {Col, Row} from 'antd';
import {VerticalSpace} from 'components/space/vertical-space';

import {ReactComponent as EID} from './icons/eID.svg';
import {ReactComponent as MID} from './icons/mID.svg';
import {URL_LOGIN_NID_USER} from "../../../api/auth/constants";
import {useGetNidUrl} from "../../../api/auth/use-get-nid-url";

export const Identification = () => {

    const url = useGetNidUrl(URL_LOGIN_NID_USER);

    const handleClick = async () => {
        window.location.href = url.data;
    };

    return (
        <VerticalSpace direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
            <Row gutter={8}>
                <Col><MID onClick={handleClick} style={{cursor: "pointer"}}/> </Col>
                <Col><EID onClick={handleClick} style={{cursor: "pointer"}}/></Col>
            </Row>
        </VerticalSpace>
    )
}

