import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';

export const ApplicationPrintLayout = ({ isEdit = false }) => {
  const context = useMemo(() => ({ isEdit }), [isEdit]);
  return (
    <Layout>
      <Outlet context={context} />
    </Layout>
  );
};
