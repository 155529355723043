import { useCallback } from 'react';
import { Col, Modal, Row, Space } from 'antd';
import { URL_CREATE_APPLICATION, URL_CREATE_TEMP_APPLICATION } from '../../../api/application/constants';
import { usePostNoMutate } from '../../../api/application/use-post-no-mutate';
import { useSaveApplication } from '../../../api/application/use-save-application';
import { useApplication } from '../../../context/applicaton-context';
import { Form } from '../form';
import { getDataToSave, initialValues } from './utils';
import { errorMessage } from '../../../helpers/utils';
import { useNavigate } from 'react-router';
import { PATHS } from '../../../helpers/constants';
import { Button } from 'components/button';
import { APPLICATION_TYPES } from 'pages/applications/constants';

const ViewLink = ({ applicationId, type, navigate, onClose }) => {
  return (
    <Row align="center" justify="center">
      <Col span={8}>
        <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center', marginTop: '16px' }}>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              navigate(`${PATHS.APPLICATIONS_VIEW.replace(':type', type).replace(':id', applicationId)}`);
              onClose();
            }}
          >
            Համաձայնության թերթիկ
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export const ApplicationFormWrapper = ({ children, ...props }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { step, finish, addData, submittedData, cityzenData } = useApplication();

  const { mutate } = useSaveApplication(URL_CREATE_TEMP_APPLICATION, {
    onSuccess: (data) => {
      form.setFieldValue('tempApplicationId', data.data);
      finish(data.data);
    },
    onError: errorMessage,
  });

  usePostNoMutate(
    URL_CREATE_APPLICATION,
    {
      id: submittedData.id,
      tempApplicationId: submittedData.tempApplicationId,
      profileId: submittedData.profileId,
    },
    {
      retry: false,
      enabled: step === 4,
      onSuccess: (data) => {
        const modal = Modal.success({
          title: 'Շնորհակալություն',
          content: data.applicationCreateMessage,
          footer: (
            <ViewLink
              applicationId={data.applicationId}
              type={
                form.getFieldValue('profileData')
                  ? APPLICATION_TYPES.LEGAL_APPLICATION
                  : APPLICATION_TYPES.MY_APPLICATION
              }
              navigate={navigate}
              onClose={() => modal.destroy()}
            />
          ),
          closable: true,
          onCancel: () => navigate(PATHS.APPLICATIONS),
        });
      },
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.APPLICATIONS);
      },
    }
  );

  function handleValuesChange(changedValues, allValues) {
    changedValues?.familyData?.familyMembers &&
      changedValues?.familyData?.familyMembers.length &&
      changedValues?.familyData?.familyMembers.filter(Boolean)?.forEach((item, index) => {
        const isApproved = allValues.familyData?.familyMembers[index]?.isApproved;
        if (item.ssn && isApproved) {
          form.setFieldValue(['familyData', 'familyMembers', index, 'isApproved'], undefined);
        }
      });
  }

  const onFinish = useCallback(
    ({ animals = [], ...values }) => {
      const dataToSubmit = getDataToSave({ form, animals, values, step, submittedData, cityzenData });
      mutate(dataToSubmit);
      addData(dataToSubmit);
    },
    [addData, cityzenData, mutate, step, submittedData, form]
  );

  return (
    <Form
      name="application-form"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      preserve
      onValuesChange={handleValuesChange}
      {...props}
    >
      {children}
    </Form>
  );
};
