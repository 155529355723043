export const URL_CHANGE_PASSWORD = 'api/Account/ChangePassword';
export const URL_LOGIN_USER = 'api/Auth/LoginUser';
export const URL_LOGIN_NID_USER = 'api/Auth/RedirectToNid';
export const URL_NID_TOKEN_USER = 'api/Auth/NidTokenRequest';
export const URL_LOGIN2F_NID_USER = 'api/Auth/NidLogin';
export const URL_LOGIN2F_USER = 'api/Auth/2FALogin';
export const URL_CREATE_ACCOUNT = 'api/Account/CreateAccount';
export const URL_CREATE_NID_ACCOUNT = 'api/Account/CreateNidAccount';
export const URL_EMAIL_CHANGE = 'api/Account/ChangeEmail';
export const URL_FORGOT_PASSWORD = 'api/Account/ForgetPassword';
export const URL_RECOVER_PASSWORD = 'api/Account/RecoverPassword';