import {Link, useNavigate, useLocation} from 'react-router-dom';
import { Input } from '../../../components/input';
import {Button} from "../../button";
import {Form} from "../form";
import {Password} from "../../input/password";
import {VerticalSpace} from "../../space/vertical-space";
import {FormItem} from "../form-item";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {SecondaryText} from "../../typography";
import {useMutateUserData} from "../../../api/auth/use-mutate-user-data";
import {URL_LOGIN_USER} from "../../../api/auth/constants";
import {PATHS, REQUEST_TYPES} from "../../../helpers/constants";
import {useState} from "react";
import {Notification} from "../../modal/Notification";
import {errorMessage} from "../../../helpers/utils";
import {Checkbox} from "../../checkbox";
import {Col, Row} from "antd";
import { Identification } from 'pages/user-app/identification';

export const UserLogin = () => {
    const [getNavigate, setNavigate] = useState();
    const [content, setContent]= useState();

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const { mutate } = useMutateUserData(URL_LOGIN_USER, REQUEST_TYPES.POST, {
        onSuccess: (result) => {
            setNavigate({
                path: `${PATHS.ONE_TIME_PASSWORD}`,
                state: { profileId: result.data.profileId, isFirstLogin: result.data.isFirstLogin, loginParam: location.state !== null ? location.state.param : 0 },
                modal: true
            });
            setContent("Շարունակելու համար խնդրում ենք ստուգել Ձեր էլեկտրոնային փոստը։");
        },
        onError: errorMessage,
    });

    const handleCancel = () => {
        navigate(getNavigate.path , { state: getNavigate.state });
        setContent('');
        getNavigate.modal = false;
    };

    const onFinish = (values) => {
        mutate(values);
    };

    return (
        <>
            <Form name="user-login" form={form} onFinish={onFinish} autoComplete="off" layout="vertical" requiredMark={false}>
                <VerticalSpace size="large">
                    <FormItem name="email" label="Էլ․ հասցե" rules={[{ required: true }, { type: 'email' }]}>
                        <Input placeholder="մուտքանուն" prefix={<UserOutlined />} />
                    </FormItem>
                    <FormItem
                        name="password"
                        label="Գաղտնաբառ"
                        rules={[{ required: true, min: 6 }]}
                        extra={
                            <FormItem>
                                <Row>
                                    <Col span={6}>
                                        <FormItem name="remember" valuePropName="checked">
                                            <Checkbox>Հիշել </Checkbox>
                                        </FormItem>
                                    </Col>
                                    <Col span={18} align="right">
                                        <Link to={PATHS.FORGOT_PASSWORD}><SecondaryText>Մոռացել եմ գաղտնաբառը</SecondaryText></Link>
                                    </Col>
                                </Row>
                            </FormItem>
                        }
                    >
                        <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
                    </FormItem>
                    <VerticalSpace size="middle">
                        <Button htmlType="submit" block type="primary" size="large">
                            Մուտք
                        </Button>
                    </VerticalSpace>
                    <Identification />
                </VerticalSpace>
            </Form>
            {
                getNavigate?.modal &&
                <Notification centered title="Ծանուցում" footer={null} open={getNavigate.modal} onCancel={handleCancel}>
                    <p>{content}</p>
                </Notification>
            }
        </>
    )
};