import {useLocation, useNavigate, Navigate} from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import {URL_LOGIN2F_USER} from '../../../api/auth/constants';
import { useMutateUserData } from '../../../api/auth/use-mutate-user-data';
import { Password } from '../../input/password';
import { VerticalSpace } from '../../space/vertical-space';
import { Form } from '../form';
import { FormItem } from '../form-item';
import { Button } from '../../button';
import {PATHS, REQUEST_TYPES} from '../../../helpers/constants';
import { useAuth } from '../../../context/auth-context';
import {useState} from "react";
import {Notification} from "../../modal/Notification";
import {errorMessage, errorMessageOTP} from "../../../helpers/utils";

export const OneTimePassword = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [getNavigate, setNavigate] = useState();
    const [getNavigateState, setNavigateState] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent]= useState(false);
    const [getOPTThreeType, setOPTThreeType]= useState(1);

    const { login, user } = useAuth();
    const [form] = Form.useForm();
    const { mutate } = useMutateUserData(URL_LOGIN2F_USER, REQUEST_TYPES.POST, {
        onSuccess: (payload) => {
            login(payload.data);
            setIsModalOpen(true);
            setContent("Շարունակելու համար խնդրում ենք ստուգել Ձեր էլեկտրոնային փոստը։");
            setNavigate(payload.data.isFirstLogin === true ? `${PATHS.CHANGE_PASSWORD}` : location.state.loginParam === 1 ? `${PATHS.SERVICES}` : `${PATHS.BENEFIT}`);
            setNavigateState(payload.data.isFirstLogin === true ? { isFirstLogin: payload.data.isFirstLogin, loginParam: location.state.loginParam } : null)
        },
        onError: (err) => {
            setOPTThreeType(getOPTThreeType + 1);
            errorMessage(err);
        },
    });
    const onFinish = (values) => {
        if(getOPTThreeType === 3) {
            errorMessageOTP("Դուք սպառել եք մեկանգամյա գաղտաբառի մուտքագրման հնարավորությունը։ Խնդրում ենք կրկին մուտք գործել։", navigate(`/${PATHS.SIGN_IN}`));
        } else {
            const profileId = location.state ? location.state.profileId : '';
            const result = {
                ...values,
                profileId
            };
            mutate(result);
        }
    };

    const handleCancel = () => {
        navigate(getNavigate , { state: getNavigateState });
        setContent('');
        setIsModalOpen(false);
    };

    if (getNavigate && user) {
        return <Navigate to={getNavigate} replace />;
    }

    return (
        <>
            <Form
                name="user-form-otp"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
            >
                <VerticalSpace size="large">
                    <FormItem
                        name="otp"
                        label="Միանագամյա գաղտնաբառ"
                        rules={[
                            { required: true, min: 6 },
                        ]}
                    >
                        <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
                    </FormItem>
                    <VerticalSpace size="middle">
                        <Button htmlType="submit" block type="primary" size="large">
                            Հաստատել
                        </Button>
                    </VerticalSpace>
                </VerticalSpace>
            </Form>
            {
                isModalOpen &&
                <Notification centered title="Ծանուցում" footer={null} open={isModalOpen} onCancel={handleCancel}>
                    <p>{content}</p>
                </Notification>
            }
        </>
    );
};