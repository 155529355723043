import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

const Wrapper = styled(Content)`
  padding: 32px;
`;

export const DefaultUserContent = ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
);

