import { useEffect } from 'react';
import { Form } from '../../form';
import { FamilyFormForm } from './family-member-form';
import { FamilyFormFormView } from './family-member-form-view';

const FamilyMemberData = ({ fields, add, remove, viewMode = false }) => {
  const hasFamilyMember = Form.useWatch(['familyData', 'hasOtherFamilyMembers']);
  const familyMembersList = Form.useWatch(['familyData', 'familyMembers']);
  useEffect(() => {
    if (hasFamilyMember && (!familyMembersList || familyMembersList.length === 0)) {
      add();
    }
  }, [add, familyMembersList, hasFamilyMember]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
      {fields.map((field) =>
        viewMode ? (
          <FamilyFormFormView key={field.name} field={field} />
        ) : (
          <FamilyFormForm key={field.name} field={field} add={add} remove={remove} />
        )
      )}
    </div>
  );
};

export const AddFamilyMember = ({ viewMode = false }) => {
  const hasFamilyMember = Form.useWatch(['familyData', 'hasOtherFamilyMembers']);
  return (
    hasFamilyMember === true && (
      <Form.List name={['familyData', 'familyMembers']}>
        {(fields, { add, remove }) => (
          <FamilyMemberData viewMode={viewMode} fields={fields} add={add} remove={remove} />
        )}
      </Form.List>
    )
  );
};
