import { createBrowserRouter } from 'react-router-dom';
import { PublicRoutes } from './components/routes/public-route';
import { PrivateRoute } from './components/routes/private-route';
import { PATHS } from './helpers/constants';
// import { SignIn } from './pages/sign-in';
import { Application } from './pages/application';
import { ApplicationLayout } from './components/layouts/application/application-layout';
import { ApplicationPrintLayout } from './components/layouts/application/application-print-layout';
import { SignInApp } from './pages/user-app/sign-in';
import { MainLayout } from './components/layouts/main-layout';
import { Notifications } from './pages/notifications';
import { Account } from './pages/account';
import { ChangePassword } from './pages/change-password';
import { Applications } from './pages/applications';
import { OtPassword } from './pages/ot-password';
import { Services } from './pages/services';
import { Settings } from './pages/settings';
import { Calendar } from './pages/calendar';
import { SelfAssessment } from './pages/self-assessment';
import { LayoutUser } from './components/layouts/sign-in/layout-user';
import { EmailSettings } from './pages/settings/email';
import { Error403 } from './pages/error-pages/error-403';
import { ApplicationView } from './pages/application-view';
import { MainRoute } from './components/routes/main-route';
import {AppForgotPass} from "./pages/user-app/forgot-password";
import {RecoverPassword} from "./pages/recover-password";
import { Home } from "./pages/personal-cabinet/home";
import { MyServices } from "./pages/services/my-services";
import {Benefit} from "./pages/benefit";
import { ApplicationPrint } from './pages/application-print/applications';
import { FamilyAgreementPrint } from './pages/application-print/family-agreement-print';
import { DecisionPrint } from './pages/application-print/decision';
import { PersonalServicesView } from 'pages/personal-services/personal-services-view';
import {OAuthCallback} from "./components/oAuth-callback";
import {NidLoginCallback} from "./components/nid-login-callback";

export const router = createBrowserRouter([
  {
    path: PATHS.OAUTHCALLBACK,
    element: <OAuthCallback />,
  },
  {
    path: PATHS.NIDLOGINCALLBACK,
    element: <NidLoginCallback />,
  },
  {
    element: <MainRoute />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          {
            element: <LayoutUser />,
            children: [
              {
                path: PATHS.ROOT,
                element: <MyServices />,
              },
              {
                path: PATHS.SIGN_IN,
                element: <SignInApp />,
              },
              {
                path: PATHS.CHANGE_PASSWORD,
                element: <ChangePassword />,
              },
              {
                path: PATHS.ONE_TIME_PASSWORD,
                element: <OtPassword />,
              },
              {
                path: PATHS.CHANGE_EMAIL,
                element: <EmailSettings />,
              },
              {
                path: PATHS.ERROR_403,
                element: <Error403 />,
              },
                {
                path: PATHS.FORGOT_PASSWORD,
                element: <AppForgotPass/>,
              },
              {
                  path: PATHS.RECOVER_PASSWORD,
                  element: <RecoverPassword />,
              },
            ],
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <MainLayout />,
            children: [
              // Home Page
              {
                path: PATHS.HOME,
                element: <Home />,
              },
              // Notification
              {
                path: PATHS.NOTIFICATIONS,
                element: <Notifications />,
              },
              // Benefit
              {
                path: PATHS.BENEFIT,
                element: <Benefit />,
              },
              // Account
              {
                path: PATHS.ACCOUNT,
                element: <Account />,
              },
              // Applications
              {
                path: PATHS.APPLICATIONS,
                element: <Applications />,
              },
              {
                element: <ApplicationLayout hideHeader />,
                children: [
                  {
                    element: <Applications />,
                    children: [
                      {
                        path: PATHS.APPLICATIONS_VIEW,
                        element: <ApplicationView />,
                      },
                      {
                        path: PATHS.APPLICATIONS_INCOMPLETE_VIEW,
                        element: <ApplicationView />,
                      },
                    ],
                  },
                ],
              },
              // Services
              {
                path: PATHS.SERVICES,
                element: <Services />,
              },
              // Settings
              {
                path: PATHS.SETTINGS,
                element: <Settings />,
              },
              {
                element: <ApplicationLayout hideHeader />,
                children: [
                  {
                    element: <Services />,
                    children: [
                      {
                        path: PATHS.SERVICES_VIEW,
                        element: <PersonalServicesView />,
                      }
                    ],
                  },
                ],
              },
              // Calendar
              {
                path: PATHS.CALENDAR,
                element: <Calendar />,
              },
              // Self Assessment
              {
                path: PATHS.SELF_ASSESSMENT,
                element: <SelfAssessment />,
              },
            ],
          },
          {
            element: <ApplicationLayout />,
            children: [
              {
                path: PATHS.APPLICATION,
                element: <Application />,
              },
            ],
          },
          {
            element: <ApplicationLayout isEdit />,
            children: [
              {
                path: PATHS.APPLICATIONS_UPDATE,
                element: <Application />,
              }
            ],
          },
          {
            element: <ApplicationPrintLayout />,
            children: [
              // Prints
              {
                path: PATHS.APPLICATIONS_PRINT,
                element: <ApplicationPrint />,
              },
              {
                path: PATHS.FAMILY_AGREEMENT_PRINT,
                element: <FamilyAgreementPrint />,
              },
              {
                path: PATHS.DECISION_PRINT,
                element: <DecisionPrint />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
