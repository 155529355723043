import {Col, Row, Spin} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

import {COLORS} from '../../helpers/constants';
import {Button} from '../button';
import {WarningSection} from '../card/warning-section';
import {VerticalSpace} from '../space/vertical-space';
import {Text} from '../typography';
import {PersonMainData} from './person-main-data';
import {useEffect, useState} from 'react';
import {Form} from './form';
import {useGetValidateUser} from '../../api/benefit/use-get-validate-user';
import {ErrorText} from '../typography/error-text';
import {dateConvert, errorMessage} from '../../helpers/utils';
import {SuccessText} from '../typography/success-text';
import {PersonContactForm} from './person-contact-form';

export const PersonDataSearchForm = ({formItemProp, url, onSuccess, onSelect, parent = [], locationRes}) => {
    const [search, setSearch] = useState(false);
    const form = Form.useFormInstance();

    const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], {preserve: true});

    const onSearch = () => {
        const ssn = form.getFieldValue([...parent, ...formItemProp.ssn.name]);
        const ssnHasError = form.getFieldError([...parent, ...formItemProp.ssn.name]);
        const firstName = form.getFieldValue([...parent, ...formItemProp.fname.name]);
        const lastName = form.getFieldValue([...parent, ...formItemProp.lname.name]);
        const patronymicName = form.getFieldValue([...parent, ...formItemProp.patronymicName.name]);
        const birthdate = dateConvert(form.getFieldValue([...parent, ...formItemProp.birthdate.name]));

        if (ssn && firstName && lastName && birthdate && !ssnHasError.length) {
            setSearch({ssn, firstName, lastName, patronymicName, birthdate});
        }
    };

    useEffect(() => {
        if (locationRes !== null) {
            const timeoutId = setTimeout(() => {
                onSearch();
            }, 200)

            return () => clearTimeout(timeoutId)
        }
    })

    const {isInitialLoading} = useGetValidateUser(url, search, {
        enabled: !!search.ssn,
        onSuccess: (data) => {
            onSuccess(data);
            formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
            onSearch();
        },
        select: (data) => onSelect?.(data) || data.data,
        onError: (error) => {
            errorMessage(error);
            onSearch();
        },
    });

    return (
        <Spin spinning={isInitialLoading}>
            <VerticalSpace>
                <PersonMainData formItemProp={formItemProp}/>
                {(locationRes === null || locationRes === undefined) && (
                    <Row justify="center">
                        <Col>
                            <Button size="large" icon={<SearchOutlined/>} type="default" onClick={onSearch}>
                                Որոնել
                            </Button>
                        </Col>
                    </Row>
                )}
                {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել"/>}
                {isApproved === true && <SuccessText text="Դիմումատուն հաջողությամբ նույնականացվեց"/>}
                {formItemProp.contact && <PersonContactForm formItemContactProp={formItemProp.contact}/>}
                {(locationRes === null || locationRes === undefined) && (
                    <WarningSection>
                        <Text color={COLORS.SECONDARY.GRAY_DARK}>
                            Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան
                        </Text>
                    </WarningSection>
                )}
            </VerticalSpace>
        </Spin>
    );
};
