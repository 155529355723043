import { VerticalSpace } from '../../../space/vertical-space';
import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { Text, Title } from '../../../typography';
import { COLORS } from '../../../../helpers/constants';
import { ActualResidentialAddress } from '../actual-residential-address';
import { PersonAddress } from '../../person-address';
import { BuildingInfo } from './building-info';
import { Form } from '../../form';
import { BuildingInfoView } from './building-info-view';
import { useApplicationForText } from 'hooks/application/use-application-for-text';

export const RegistrationAddress = ({
  formItemRegAddress = {},
  formItemActualAddressStepTwo,
  formItemAddressType,
  viewMode = false,
}) => {
  const isActualAddressMatch = Form.useWatch(formItemRegAddress.isActualAddressMatch.name);

  return (
    <FormSectionCard title="Հաշվառման հասցե">
      <VerticalSpace>
        <PersonAddress viewMode={viewMode} formItemAddress={formItemRegAddress} />
        <FormItem
          label={
            <Title level={1} as="p" color={COLORS.PRIMARY.BLUE}>
              {`Բնակվում ${useApplicationForText('եմ իմ', 'է իր')} հաշվառման հասցեում`}
            </Title>
          }
          rules={[{ required: true }]}
          {...formItemRegAddress.isActualAddressMatch}
        >
          <Radio.Group>
            <Space>
              <Radio value={true}>
                <Text color={COLORS.PRIMARY.BLUE_DARK}>Այո</Text>
              </Radio>
              <Radio value={false}>
                <Text color={COLORS.PRIMARY.BLUE_DARK}>Ոչ</Text>
              </Radio>
            </Space>
          </Radio.Group>
        </FormItem>
        {isActualAddressMatch === false && !viewMode && <BuildingInfo formItemAddressType={formItemAddressType} />}
        {isActualAddressMatch === false && viewMode && <BuildingInfoView />}

        <ActualResidentialAddress viewMode={viewMode} formItemActualAddressStepTwo={formItemActualAddressStepTwo} />
      </VerticalSpace>
    </FormSectionCard>
  );
};
