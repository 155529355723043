import { MainSider } from '../menu/main-sider';
import { UserNavbar } from '../menu/user-navbar';
import { SidebarProfile } from '../profile/sidebar-profile';

export const DefaultSider = () => {
  return (
    <MainSider
      style={{ backgroundColor: 'rgba(219, 233, 253, 0.8)', padding: '130px 12px', height: '100%', minHeight: '100vh' }}
      width={369}
      breakpoint="lg"
      collapsedWidth="0"
      collapsible
    >
      <SidebarProfile />
      <UserNavbar />
    </MainSider>
  );
};
