import { useState } from 'react';
import {Link} from 'react-router-dom';
import { Spin, Divider, Row, Col } from 'antd';
import dayjs from 'dayjs';

import {COLORS, DEFAULT_PAGE, PATHS} from '../../helpers/constants';
import { useGetNotifications } from '../../api/notifications/use-get-notification';
import { ReactComponent as Ellipse } from './images/Ellipse.svg';
import { GET_NOTIFICATIONS } from '../../api/notifications/constants';

export const SiderNotifications = () => {
    const [page] = useState({ ...DEFAULT_PAGE, pageSize: 3 });
    const [result, setResult] = useState({ data: [], totalRecords: 0 });

    const { isLoading } = useGetNotifications(GET_NOTIFICATIONS, page, {
        onSuccess: (data) =>
            setResult(() => ({
                data: data.data,
                totalRecords: data.totalRecords,
            })),
        enabled: true,
    });

    return (
        <Spin spinning={isLoading}>
            <div>
                <Divider></Divider>
                {result?.data && result?.data?.map((item) => {
                    return (
                        <div key={item?.id}>
                            <Row gutter={4} justify="space-around" align="middle">
                                <Col span={2}>
                                    {item?.isRead === false && <Ellipse />}
                                </Col>
                                <Col span={22}>
                                    <p><div dangerouslySetInnerHTML={{ __html: item?.notificationText }}></div></p>

                                </Col>
                                <Col span={24} push={2}>
                                    <div style={{ color: "#0F579E" }}>{item?.dateProcessed && dayjs(item?.dateProcessed).format('DD/MM/YYYY HH:mm:ss')}</div>
                                </Col>
                            </Row>
                            <Divider style={{ margin: "10px 0" }}></Divider>
                        </div>

                    )
                })}
                {result.data && <Link to={PATHS.NOTIFICATIONS} style={{ display: 'flex', justifyContent: 'center', color: COLORS.PRIMARY.BLUE }}>Տեսնել ավելին</Link>}
            </div>
        </Spin>

    )
}
