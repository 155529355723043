export const menuText = {
    lostJob: 'Կորցրել եմ աշխատանքս',
    needAllowance: {
        label: 'Ունեմ նպաստի կարիք',
        subMenu: {
            insecurityAllowance: 'Դիմում Ընտանիքի անապահովության նպաստ ստանալու մասին',
            applicationAppeal: 'Դիմում բողոքարկում',
            firstGrade: 'Դիմում առաջին դասարան ընդունվելու դեպքում միանվագ օգնության մասին',
            emergencyAssistance: 'Դիմում եռամսյա հրատապ օգնություն ստանալու մասին',
            familyComposition: 'Դիմում ընտանիքի կազմի փոփոխության մասին',
            changeAddress: 'Դիմում հասցեի փոփոխության մասին',
        }
    },
    receivePension: (<>
            Ցանկանում եմ ստանալ կենսաթոշակ
            <br/>
            /պարգևավճար/այլ դրամական վճար
        </>
    ),
    goingHaveBaby: 'Պատրաստվում եմ երեխա ունենալ',
    needCare: 'Ունեմ խնամքի կարիք',
    needApartment: 'Ունեմ բնակարանի կարիք',
    functionalEvaluation: (<>
            Ցանկանում եմ անցնել
            <br/>
            ֆունկցիոնալության գնահատում
        </>
    ),
    depositRefund: (<>
            Ցանկանում եմ ստանալ ավանդի
            <br/>
            փոխհատուցում
        </>
    ),
    difficultSituation: (<>
            Հայտնվել եմ կյանքի դժվարին
            <br/>
            իրավիճակում
        </>
    ),
};