import { Checkbox, Col, Row } from 'antd';
import { InputNumber } from '../../../input-number';
import { Input } from '../../../input';
import { Form } from '../../form';
import { FormItem } from '../../form-item';

export const AnimalForm = ({ animal }) => {
  const animalState = Form.useWatch(['animals', animal.id, 'id']);

  return (
    <Row justify="space-between">
      <Col span={12}>
        <FormItem valuePropName="checked" name={['animals', animal.id, 'id']}>
          <Checkbox>{animal.name}</Checkbox>
        </FormItem>
        <FormItem hidden name={['animals', animal.id, 'name']} initialValue={animal.name}>
          <Input />
        </FormItem>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <FormItem name={['animals', animal.id, 'count']} rules={[{ required: !!animalState }]}>
          <InputNumber
            disabled={!animalState}
            min={1}
            placeholder="Նշել գլխաքանակ"
            style={{ width: '160px', float: 'right' }}
          />
        </FormItem>
      </Col>
    </Row>
  );
};
