import { MainSider } from '../menu/main-sider';
import {ServicesNavbar} from "../menu/services-navbar";
import {COLORS} from "../../helpers/constants";

export const ServicesSider = () => {

    return (
        <MainSider
            style={{ backgroundColor: COLORS.SECONDARY.OIL, padding: '130px 12px' }}
            width={400}
            breakpoint="lg"
            collapsedWidth="0"
            collapsible
        >
            <ServicesNavbar />
        </MainSider>
    );
};
