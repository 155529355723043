import { Spin } from 'antd';
import { URL_GET_INCOMPLETE_APPLICATION, URL_GET_APPLICATION } from '../../../api/application/constants';
import { useDataForApplication } from '../../../api/application/use-get-data-for-application';
import { Form } from '../form';
import { useParams } from 'react-router-dom';
import { citizenInfo, files, formItemStepTwoParent, utilityAccounts } from '../../../pages/application/constants';
import dayjs from 'dayjs';
import { ApplicationProvider } from 'context/applicaton-context';

export const ApplicationViewFormWrapper = ({ children, ...props }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const url = params.status && params.status === 'incomplete' ? URL_GET_INCOMPLETE_APPLICATION : URL_GET_APPLICATION;
  const { isInitialLoading } = useDataForApplication(
    url,
    { [params.status === 'incomplete' ? 'tempApplicationId' : 'applicationId']: params.id },
    {
      onSuccess: (data) => {
        form.setFieldsValue({
          ...data,
          agreementFilesChecksum: data.agreementFilesChecksum?.map((item, index) => ({
            uid: item,
            name: `Տեղեկանք ${index + 1}`,
            status: 'done',
            response: {
              fileChecksum: item,
            },
          })),
          bankId: data.applicationProperties?.bankId,
          paymentMethodId: data.applicationProperties?.paymentMethodId,
          [formItemStepTwoParent]: {
            ...data[formItemStepTwoParent],
            [utilityAccounts]: {
              gasAccount: data[formItemStepTwoParent].gasAccount,
              electricityAccount: data[formItemStepTwoParent].electricityAccount,
              waterAccount: data[formItemStepTwoParent].waterAccount,
            },
            [citizenInfo]: {
              ...data[formItemStepTwoParent][citizenInfo],
              fname: data[formItemStepTwoParent][citizenInfo]['firstName'],
              lname: data[formItemStepTwoParent][citizenInfo]['lastName'],
              birthdate: dayjs(data[formItemStepTwoParent][citizenInfo]['birthdate']),
            },
            [files]: {
              animalFilesChecksum: data.citizenData.animalFilesChecksum?.map((item, index) => ({
                uid: item,
                name: `Տեղեկանք ${index + 1}`,
                status: 'done',
                response: {
                  fileChecksum: item,
                },
              })),
              militaryServiceFilesChecksum: data.citizenData.militaryServiceFilesChecksum?.map((item, index) => ({
                uid: item,
                name: `Տեղեկանք ${index + 1}`,
                status: 'done',
                response: {
                  fileChecksum: item,
                },
              })),
              studyFilesChecksum: data.citizenData.studyFilesChecksum?.map((item, index) => ({
                uid: item,
                name: `Տեղեկանք ${index + 1}`,
                status: 'done',
                response: {
                  fileChecksum: item,
                },
              })),
              residenceFilesChecksum: data.citizenData.residenceFilesChecksum?.map((item, index) => ({
                uid: item,
                name: `Տեղեկանք ${index + 1}`,
                status: 'done',
                response: {
                  fileChecksum: item,
                },
              })),
            },
          },
          profileData: data.profileData
            ? {
              ...data.profileData,
              legalRepresentative: {
                ...data.profileData.legalRepresentative,
                fname: data.profileData.legalRepresentative['firstName'],
                lname: data.profileData.legalRepresentative['lastName'],
                birthdate: dayjs(data.profileData.legalRepresentative['birthdate']),
                email: data.profileData.email,
                gender: data.profileData.gender,
                phoneNumber: data.profileData.phoneNumber,
              },
              legalFilesChecksum: data.profileData?.legalFilesChecksum?.map((item, index) => ({
                uid: item,
                name: `Տեղեկանք ${index + 1}`,
                status: 'done',
                response: {
                  fileChecksum: item,
                },
              })),
            }
            : null,
          familyData: data.familyData
            ? {
                ...data.familyData,
                familyMembers: [
                  ...data.familyData.familyMembers.map((item) => ({
                    ...item,
                    ...item.citizenInfo,
                    birthdate: dayjs(item.citizenInfo.birthdate),
                    fname: item.citizenInfo.firstName,
                    lname: item.citizenInfo.lastName,
                    [files]: {
                      militaryServiceFilesChecksum: item.militaryServiceFilesChecksum?.map((itemMil, index) => ({
                        uid: itemMil,
                        name: `Տեղեկանք ${index + 1}`,
                        status: 'done',
                        response: {
                          fileChecksum: itemMil,
                        },
                      })),
                      studyFilesChecksum: item.studyFilesChecksum?.map((itemSt, index) => ({
                        uid: itemSt,
                        name: `Տեղեկանք ${index + 1}`,
                        status: 'done',
                        response: {
                          fileChecksum: itemSt,
                        },
                      })),
                    },
                  })),
                ],
              }
            : null,
        });
      },
    }
  );

  return (
    <Spin spinning={isInitialLoading}>
      <ApplicationProvider viewMode>
        <Form name="application-form" form={form} layout="vertical" disabled {...props}>
          {children}
        </Form>
      </ApplicationProvider>
    </Spin>
  );
};
