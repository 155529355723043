import { useState } from 'react';
import { Col, Row, Form, Spin, message } from 'antd';
import dayjs from 'dayjs';

import { FormSectionCard } from '../../components/card/form-section-card';
import { FormItem } from '../../components/form/form-item';
import { Input } from '../../components/input';
import { Datepicker } from '../../components/datepicker';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { VerticalSpace } from '../../components/space/vertical-space';
import { useSaveApplication } from '../../api/application/use-save-application';
import { errorMessage } from '../../helpers/utils';
import { URL_ACCOUNT_INFO, URL_UPDATE_PHONE_NUMBER } from '../../api/application/constants';
import {phonePattern} from 'helpers/constants';
import {MyProfile} from "./my-profile";


export const Account = () => {
  const [form] = Form.useForm();
  const [phoneReadOnly, setPhoneReadOnly] = useState(true);

  const { isLoading } = useDataForApplication(
    URL_ACCOUNT_INFO,
    {},
    {
      onSuccess: ({ birthdate, ...data }) => {
        form.setFieldsValue({
          ...data,
          birthdate: birthdate && dayjs(birthdate),
        });
      },
      enabled: true,
    }
  );
  const { mutate } = useSaveApplication(URL_UPDATE_PHONE_NUMBER, {
    onSuccess: () => {
      message.success(`Տվյալները հաջողությամբ պահվեցին`);
      setPhoneReadOnly(!phoneReadOnly);
    },
    onError: errorMessage,
  });

  const handleEdit = () => {
    setPhoneReadOnly(!phoneReadOnly);
  };
  const handleSave = () => {
    const phonenumber = form.getFieldValue('phoneNumber');
    mutate({ phonenumber });
  };
  return (
    <Form autoComplete="off" layout="vertical" form={form}>
      <Spin spinning={isLoading}>
        <VerticalSpace>
          <FormSectionCard title="Անձնական տվյալներ">
            <Row gutter={16}>
              <Col md={24} lg={12}>
                <Row gutter={8}>
                  <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MyProfile/>
                  </Col>
                  <Col md={24} lg={16}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <FormItem label="Անուն" name="firstName">
                          <Input size="middle" disabled />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Ազգանուն" name="lastName">
                          <Input size="middle" disabled />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={24} lg={12}>
                <Row gutter={8}>
                  <Col span={24}>
                    <FormItem label="ՀԾՀ/ՀԾՀ չունենալու վերաբերյալ տեղեկանքի համար" name="ssn">
                      <Input size="middle" disabled />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Հայրանուն" name="patronymicName">
                      <Input size="middle" disabled />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={24} lg={12}>
                <FormItem label="Սեռ" name="sex">
                  <Input size="middle" disabled />
                </FormItem>
              </Col>
              <Col md={24} lg={12}>
                <FormItem label="Ծննդյան ամսաթիվ " name="birthdate">
                  <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled />
                </FormItem>
              </Col>
            </Row>
          </FormSectionCard>
          <FormSectionCard>
            <Row gutter={16}>
              <Col md={24} lg={8}>
                <FormItem label="Էլ․փոստի հասցե" name="email">
                  <Input size="middle" disabled />
                </FormItem>
              </Col>
              <Col md={24} lg={8}>
                <FormItem
                  label="Հեռախոս"
                  name="phoneNumber"
                  rules={[{ pattern: phonePattern, message: 'Ֆորմատը սխալ է {+37400000000}' }]}
                >
                  <Input
                    size="middle"
                    readOnly={phoneReadOnly}
                    suffix={
                      phoneReadOnly ? (
                        <EditOutlined onClick={() => handleEdit()} />
                      ) : (
                        <CheckOutlined onClick={() => handleSave()} />
                      )
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          </FormSectionCard>
        </VerticalSpace>
      </Spin>
    </Form>
  );
};
