import { Col, Row } from 'antd';
import { Checkbox } from 'components/checkbox';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';
import { Input } from 'components/input';
import { useEffect, useState } from 'react';

export const UtilityItem = ({ item }) => {
  const form = Form.useFormInstance();
  const itemValue = form.getFieldValue(item.name);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (itemValue) {
      setIsChecked(true);
    }
  }, [itemValue, item.name]);

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
    if (!e.target.checked) {
      form.setFieldValue(item.name, null);
    }
  };
  return (
    <Row>
      <Col span={1}>
        <Checkbox checked={isChecked} onChange={handleCheckChange} />
      </Col>
      <Col span={7}>
        <FormItem {...item} rules={[{ required: isChecked }]}>
          <Input disabled={!isChecked} />
        </FormItem>
      </Col>
    </Row>
  );
};
