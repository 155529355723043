import {Menu as MenuComponent} from 'antd';
import {DownSquareOutlined} from "@ant-design/icons";
import {Link, useMatches} from "react-router-dom";
import {COLORS, PATHS} from "../../helpers/constants";
import styled from "styled-components";
import {useAuth} from "../../context/auth-context";
import {menuText} from "../../pages/services/constant";

function getItem(label, key, children, type) {
    return {
        key,
        children,
        label,
        type,
    };
}

export const SubMenu = styled(MenuComponent)`
    .ant-menu-submenu {
        max-height: 185px;
        overflow: auto;
    }
    
    .ant-menu-submenu > .ant-menu-submenu-title {
        height: fit-content;
    }
    
    .ant-menu-title-content {
      line-height: 1.6;
      margin-bottom: 15px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const ServicesNavbar = () => {
    const match = useMatches();
    const {user} = useAuth();

    const items = [
        getItem(<span title={menuText.lostJob}>{menuText.lostJob}</span>, '1', []),
        getItem(<span title={menuText.needAllowance.label}>{menuText.needAllowance.label}</span>, '2', [
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.insecurityAllowance}>{menuText.needAllowance.subMenu.insecurityAllowance}</Link>, '3'),
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.applicationAppeal}>{menuText.needAllowance.subMenu.applicationAppeal}</Link>, '4'),
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.firstGrade}>{menuText.needAllowance.subMenu.firstGrade}</Link>, '5'),
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.emergencyAssistance}>{menuText.needAllowance.subMenu.emergencyAssistance}</Link>, '6'),
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.familyComposition}>{menuText.needAllowance.subMenu.familyComposition}</Link>, '7'),
            getItem(<Link to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
                          title={menuText.needAllowance.subMenu.changeAddress}>{menuText.needAllowance.subMenu.changeAddress}</Link>, '8'),
        ]),
        getItem(<span title={menuText.receivePension}>{menuText.receivePension}</span>, '9', []),
        getItem(<span title={menuText.goingHaveBaby}>{menuText.goingHaveBaby}</span>, '10', []),
        getItem(<span title={menuText.needCare}>{menuText.needCare}</span>, '11', []),
        getItem(<span title={menuText.needApartment}>{menuText.needApartment}</span>, '12', []),
        getItem(<span title={menuText.functionalEvaluation}>{menuText.functionalEvaluation}</span>, '13', []),
        getItem(<span title={menuText.depositRefund}>{menuText.depositRefund}</span>, '15', []),
        getItem(<span title={menuText.difficultSituation}>{menuText.difficultSituation}</span>, '16', []),
    ];

    return (
        <>
            <SubMenu
                style={{
                    width: "100%",
                    height: 200,
                    background: "none",
                    color: COLORS.PRIMARY.WHITE,
                }}
                expandIcon={<DownSquareOutlined/>}
                selectedKeys={match.map((item) => item.pathname)}
                mode="inline"
                items={items}
            />
        </>
    );
};