import styled from 'styled-components';

const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
  
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    height: 140px;
  }
`;

export const PageTitle = () => {
    return <Title>ՀՀ ՍՈՑԻԱԼԱԿԱՆ ՊԱՇՏՊԱՆՈՒԹՅԱՆ ՈԼՈՐՏԻ ԴԻՄՈՒՄՆԵՐԻ ՄԻԱՍՆԱԿԱՆ ՀԱՄԱԿԱՐԳ</Title>;
};
