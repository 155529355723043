import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const MainSider = styled(Sider)`
  background-color: #0b847f;
  padding: 24px;
`;

