export const PATHS = {
  ROOT: '/',
  UI: '/ui',
  SIGN_IN: '/sign-in',
  HOME: '/home',
  APPLICATION: '/application',
  APPLICATIONS: '/applications',
  APPLICATIONS_UPDATE: '/applications/edit/:id',
  APPLICATIONS_VIEW: '/applications/view/:type/:id',
  APPLICATIONS_INCOMPLETE_VIEW: '/applications/view/:status/:type/:id',
  ERROR_403: 'no-access',
  DASHBOARD: '/dashboard',
  NOTIFICATIONS: '/notifications',
  ACCOUNT: '/account',
  CHANGE_PASSWORD: '/password-reset',
  ONE_TIME_PASSWORD: '/one-time-password',
  SERVICES: '/services',
  SETTINGS: '/settings',
  CALENDAR: '/calendar',
  SELF_ASSESSMENT: '/self-assessment',
  CHANGE_EMAIL: '/change_email',
  HOT_LINE: 'https://socservice.am/contact-us',
  FORGOT_PASSWORD: '/forgot-password',
  RECOVER_PASSWORD: '/recover-password',
  APPLICATIONS_PRINT: '/applications/print/:id',
  BENEFIT: '/benefit',
  FAMILY_AGREEMENT_PRINT: '/applications/family-agreement-print/:id',
  DECISION_PRINT: '/decision/print/:id',
  SERVICES_VIEW: '/services/view/:type/:id',
  OAUTHCALLBACK: '/oAuth-callback',
  NIDLOGINCALLBACK: '/nidLogin-callback',
};
export const COLORS = {
  PRIMARY: {
    BLUE: '#456FAE',
    BLUE_LIGHT: '#DBE9FD',
    BLUE_DARK: '#091024',
    GRAY_DARK: '#414141',
    GRAY: '#F4F4F4',
    GRAY_LIGHT: '#969393',
    GRAY_MEDIUM: '#F0F2F5',
    WHITE: '#FFFFFF',
    SILVER: '#BFBFBF',
    SILVER_LIGHT: '#E2E0E8',
  },
  ALERT: {
    RED: '#ed1b1b',
    GRAY: '#808080',
    GREEN: '#5ACA75',
    WHITE_LIGHT: '#F2F2F2',
  },
  SECONDARY: {
    BLUE: '#4D7EC7',
    AQUA_BLUE: '#4874CC',
    ALICE_BLUE: '#F0F0F2',
    CYAN: '#4DC7B5',
    MAGENTA: '#F27281',
    MAGENTA_LIGHT: '#D789D9',
    YELLOW: '#F5B452',
    YELLOW_LIGHT: '#EDE06D',
    YELLOW_DARK: '#FDC611',
    GRAY_DARK: '#555965',
    GRAY_MIDLIGHT: '#717B8A',
    GRAY_LIGHT: '#8A91AE',
    MAXIMUM_BLUE: '#4DAAC7',
    GREEN_LIGHT: '#4DC78C',
    BLUE_MEDIUM: '#7F6DED',
    LAVANDER: '#AC89D9',
    PURPLE_MIDDLE: '#D989AF',
    PEARL_AQUA: '#89D9C6',
    PASTEL_BLUE: '#B8D4D6',
    PASTEL_LIGHT: '#DEE6F1',
    PURPLE_BLUE: '#A2ACDE',
    AERO_BLUE: '#BBDACB',
    LAVANDER_DARKL: '#C7BDD4',
    PINK: '#DBAFA1',
    OIL: '#3B3F5F',
  },
};

export const AUTH_KEYS = {
  USER: 'npast-ams-user',
  TOKEN: 'npast-ams-token',
};

export const VARIABLES = {
  MAX_PROJECT_TITLE_LENGTH: 15,
};

export const DEFAULT_ICON = 'logo-small';
export const DEFAULT_COLOR = COLORS.PRIMARY.GRAY_LIGHT;

export const screenSize = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const PAGE_SIZE = 10;
export const PAGE_NUMBER = 1;
export const DEFAULT_PAGE = { pageNumber: PAGE_NUMBER, pageSize: PAGE_SIZE };

export const USER_TYPES = {
  0: 'Սուպեր ադմինիստրատոր',
  1: 'Ադմինիստրատոր',
  2: 'Օպերատոր',
  3: 'Սոցիալական աշխատող',
  4: 'Նախարարության աշխատակից',
};
export const USERS = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  OPERATOR: 2,
  SOC_WORKER: 3,
  GOV: 4,
};

export const LOTTIE_ICON = {
  CALENDAR_ICON: 'https://assets4.lottiefiles.com/packages/lf20_ilp95ggh.json',
  DOCUMENT_ICON: 'https://lottie.host/43471e35-da56-4591-9a87-80fde115de7a/2L5F4nK40e.json',
  NOTIFICATION_ICON: 'https://assets4.lottiefiles.com/packages/lf20_zlLgtuiNAq.json',
  OPTION_ICON: 'https://assets1.lottiefiles.com/packages/lf20_xtvomr66.json',
  SELF_ASSESMENT_ICON: 'https://lottie.host/d8b02362-c4f8-4356-a936-71f8fadaee4a/CxdC9nuZMQ.json',
  SERVICE_ICON: 'https://assets9.lottiefiles.com/packages/lf20_p4q9ra7d.json',
};

export const CREATE_USER_ENDPOINTS = {
  [USERS['ADMIN']]: '/api/User/CreateAdmin',
  [USERS['GOV']]: '/api/User/CreateGovUser',
  [USERS['OPERATOR']]: '/api/User/CreateOperator',
  [USERS['SOC_WORKER']]: '/api/User/CreateSocWorker',
};

export const VALIDATE_MESSAGES = {
  required: 'Պարտադիր դաշտ',
  types: {
    email: '${label} դաշտը վավեր չէ',
    number: '${label} դաշտը վավեր չէ',
  },
  number: {
    range: '${label}ը պետք է լինի ${min} և ${max}',
  },
};

export const DATE_FORMAT_YYYYMMDD = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const REQUEST_TYPES = {
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  GET: 'get',
};

export const APPLICATION_STATUSES = [
  {
    text: 'Ստեղծված',
    value: 'Ստեղծված',
  },
  {
    text: 'Ընթացիկ',
    value: 'Ընթացիկ',
  },
  {
    text: 'Հաստատված',
    value: 'Հաստատված',
  },
  {
    text: 'Մերժված',
    value: 'Մերժված',
  },
];

export const phonePattern = /^\+374\d{8}$/;

export const emailPattern =
  /^(?=.{1,256})(?=.{1,64}@.{1,255}$)(?=\S)(?:(?!@)[\w&'*+._%-]+(?:(?<!\\)[.,;])?)*(?<=\S)@(?=\S)(?!-)[A-Za-z0-9-]{1,63}(?<!-)(?:\.(?!-)[A-Za-z0-9-]{1,63}(?<!-))*$/;

export const armenianLettersPattern =
  /^[\u0561-\u0587\u0531-\u0556]+(\s?[\u0561-\u0587\u0531-\u0556]+)*(-[\u0561-\u0587\u0531-\u0556]+(\s[\u0561-\u0587\u0531-\u0556]+)?)?$/;

export const DOCUMENT_TYPES = {
  LEGAL_REPRESENTITIVE: 1,
  FROM_HOME_PLACE: 2,
  ANIMAL: 3,
  FAMILY_MEMEBER: 4,
  EDUCATION: 5,
  MILITARY: 6,
};

export const PAY_METHOD = {
  BANK: 0,
  CASH: 1,
};
