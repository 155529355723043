import { SubmitButton } from '../../components/button';
import { AddFamilyMember } from '../../components/form/application/family/add-family-member';
import { HasFamilyMember } from '../../components/form/application/family/has-family-member';
import { VerticalSpace } from '../../components/space/vertical-space';
import { useApplication } from '../../context/applicaton-context';

export const ThirdStep = () => {
  const { step, jump } = useApplication();
  return (
    <VerticalSpace size="large">
      <HasFamilyMember />
      <AddFamilyMember />
      <SubmitButton
        submitText="Հաջորդ"
        canceltext="Նախորդ"
        onCancel={() => {
          jump(step - 1);
        }}
      />
    </VerticalSpace>
  );
};
