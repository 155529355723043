import { Tabs } from 'antd';
import { InvolvedApplications } from './components/involved-applications';
import { LegalApplications } from './components/legal-applications';
import { MyApplication } from './components/my-application';
import { APPLICATION_TYPES } from './constants';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const Applications = () => {
  const [activeTab, setActiveTab] = useState();
  const params = useParams();
  const onChange = (val) => {
    setActiveTab(val);
  };

  useEffect(() => {
    params.type && setActiveTab(+params.type);
  }, [params.type]);

  const items = [
    {
      key: APPLICATION_TYPES.MY_APPLICATION,
      label: `Իմ դիմումները`,
      children: +params.type === APPLICATION_TYPES.MY_APPLICATION ? <Outlet /> : <MyApplication />,
    },
    {
      key: APPLICATION_TYPES.OTHER_APPLICATION,
      label: `Այլ դիմումներում ներգրավվածություն`,
      children: +params.type === APPLICATION_TYPES.OTHER_APPLICATION ? <Outlet /> : <InvolvedApplications />,
    },
    {
      key: APPLICATION_TYPES.LEGAL_APPLICATION,
      label: `Օրինական ներկայացուցչի դիմումներ`,
      children: +params.type === APPLICATION_TYPES.LEGAL_APPLICATION ? <Outlet /> : <LegalApplications />,
    },
  ];

  return (
    <Tabs defaultActiveKey={1} activeKey={activeTab} items={items} onChange={onChange} destroyInactiveTabPane={true} />
  );
};
