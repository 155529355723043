import {useNavigate} from 'react-router-dom';
import {PATHS} from '../../helpers/constants';
import {useAuth} from '../../context/auth-context';
import {Title} from '../typography';
import {VerticalSpace} from "../space/vertical-space";
import {Button} from "antd";
import {MyProfile} from "../../pages/account/my-profile";

export const Profile = () => {
    const navigate = useNavigate();
    const {user} = useAuth();

    return (
        <Button type="block" onClick={() => navigate(`/${PATHS.HOME}`)} style={{cursor: 'pointer', height: "fit-content"}}>
            <VerticalSpace id="profile" align="center">
                    <MyProfile/>
                <Title level={3}>{user?.firstName}</Title>
                <Title level={3}>{user?.lastName}</Title>
            </VerticalSpace>
        </Button>
    );
};
