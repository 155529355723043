import { Layout as LayoutComponent } from 'antd';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { useAuth } from '../../context/auth-context';
import { PATHS } from '../../helpers/constants';

const Layout = styled(LayoutComponent)`
  background: #fdfdfd;
`;

export const PublicRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();

    if (user && location?.state?.isFirstLogin === false) {
        return <Navigate to={location?.state?.loginParam === 1 ? `${PATHS.SERVICES}` : `${PATHS.BENEFIT}`} replace />;
    }
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
