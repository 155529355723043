import {Col, Row} from "antd";
import {Text, Title} from "../../components/typography";
import {Button} from "../../components/button";
import {LottieIcon} from "../../components/lottie";
import {COLORS, LOTTIE_ICON, PATHS} from "../../helpers/constants";
import {Link} from "react-router-dom";
import {Card} from "../../components/card";
import {ContentWrapper} from "../../components/layouts/content-wrapper";


const items = [
    {
        title: 'Ծառայություններ',
        text: 'Այս բաժնում կարող եք տեսնել առաջարկվող և Ձեզ տրամադրվող սոցիալական ծառայությունները',
        icon: LOTTIE_ICON.SERVICE_ICON,
        url: PATHS.SERVICES,
    },
    {
        title: 'Իմ Դիմումները',
        text: 'Այս բաժնում կարող եք տեսնել սոցիալական ծառայությունների տրամադրման համար ներկայացված Ձեր բոլոր դիմումները',
        icon: LOTTIE_ICON.DOCUMENT_ICON,
        url: PATHS.APPLICATIONS,
    },
    {
        title: 'Ծանուցումներ',
        text: 'Այս բաժնում կարող եք տեսնել Ձեր ծանուցումները',
        icon: LOTTIE_ICON.NOTIFICATION_ICON,
        url: PATHS.NOTIFICATIONS,
    },
    {
        title: 'Կարգավորումներ',
        text: 'Այս բաժնում կարող եք կատարել Ձեր անհատական կաբինետի կարգավորումները',
        icon: LOTTIE_ICON.OPTION_ICON,
        url: PATHS.SETTINGS,
    },
    {
        title: 'Օրացույց',
        text: 'Այս բաժնում կարող եք տեսնել Ձեր սոցիալական ծառայություն(ներ)ի հետ կապված գործողությունները',
        icon: LOTTIE_ICON.CALENDAR_ICON,
        url: PATHS.CALENDAR,
    },
    {
        title: 'Ինքնագնահատում',
        text: 'Պատասխանելով այս բաժնում տրվող հարցերին` կարող եք պարզել Ձեր համար հասանելի սոցիալական ծառայություն(ներ)ը',
        icon: LOTTIE_ICON.SELF_ASSESMENT_ICON,
        url: PATHS.SELF_ASSESSMENT,
    },
]


export const Home = () => {

    return (
        <ContentWrapper style={{padding: 20, background: COLORS.SECONDARY.ALICE_BLUE}}>
            <Row gutter={[80, 42]} align="center">
                {items.map((item, key) =>
                    <Col lg={16} xl={12} xxl={8} key={key}>
                        <Card bodyStyle={{minHeight: 380}} actions={[<Button block size="large" key="view" style={{width: 150, float: 'left', margin: '0 20px'}} type="primary">
                            <Link to={item.url} style={{color:COLORS.PRIMARY.WHITE}}>Տեսնել ավելին</Link>
                        </Button>]}>
                            <LottieIcon src={item.icon}/>
                            <Title style={{fontSize:21}}>{item.title}</Title>
                            <Text style={{fontSize: 17}}>{item.text}</Text>
                        </Card>
                    </Col>
                )}
            </Row>
        </ContentWrapper>
    )
}