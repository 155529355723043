import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { APPLICATION_STATUSES, PATHS } from '../../../helpers/constants';
import { Actions } from '../../../components/button/actions';
import { URL_CLONE_APPLICATION, URL_DELETE_INCOMPLETE_APPLICATION } from '../../../api/application/constants';
import { usePostApplication } from '../../../api/account/use-post-application';
import { errorMessage } from '../../../helpers/utils';
import dayjs from 'dayjs';

export const useColumns = (type) => {
  const navigate = useNavigate();

  const { mutate: cloneMutate } = usePostApplication(URL_CLONE_APPLICATION, {
    onSuccess: () => {
      message.success(`Դիմումը հաջողությամբ կլոնավորվեց`);
    },
    onError: errorMessage,
  });
  const { mutate: deleteMutate } = usePostApplication(URL_DELETE_INCOMPLETE_APPLICATION, {
    onSuccess: () => {
      message.success(`Դիմումը հաջողությամբ ջնջվեց`);
    },
    onError: errorMessage,
  });

  const handleDelete = (id) => {
    deleteMutate({ tempApplicationId: id });
  };
  const handleClone = (id) => {
    cloneMutate({ applicationId: id });
  };
  return [
    {
      title: 'N',
      dataIndex: 'n',
      align: 'center',
      flex: 1,
      render: (_, record, key) => +key + 1,
    },
    {
      title: 'Դիմումի տեսակ',
      dataIndex: 'systemType',
      key: 'systemType',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Դիմումի ենթատեսակ',
      dataIndex: 'applicationType',
      key: 'applicationType',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Դիմումի համար',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Ներկայացման ամսաթիվ',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.createdDate, 'DD/MM/YYYY').diff(dayjs(b.createdDate, 'DD/MM/YYYY')),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      flex: 1,
      filters: APPLICATION_STATUSES,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Կարգավիճակի ստացման ամսաթիվ',
      dataIndex: 'stateChangedDate',
      key: 'stateChangedDate',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.stateChangedDate, 'DD/MM/YYYY').diff(dayjs(b.stateChangedDate, 'DD/MM/YYYY')),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => (
        <Actions
          items={item}
          onView={() =>
            navigate(
              item.statusId === 0
                ? `${PATHS.APPLICATIONS_INCOMPLETE_VIEW.replace(':type', type)
                    .replace(':id', item.applicationId)
                    .replace(':status', 'incomplete')}`
                : `${PATHS.APPLICATIONS_VIEW.replace(':type', type).replace(':id', item.applicationId)}`
            )
          }
          onEdit={() => navigate(`${PATHS.APPLICATIONS_UPDATE.replace(':id', item.applicationId)}`)}
          onPrint={() =>
            window.open(
              `${PATHS.APPLICATIONS_PRINT.replace(':id', item.applicationId)}`,
              '_blank',
              'noopener,noreferrer'
            )
          }
          onDecision={() =>
            window.open(`${PATHS.DECISION_PRINT.replace(':id', item.applicationId)}`, '_blank', 'noopener,noreferrer')
          }
          onDelete={() => handleDelete(item.applicationId)}
          onClone={() => handleClone(item.applicationId)}
        />
      ),
    },
  ];
};
